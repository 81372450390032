html {
  font-size: 16px;
}

body {
  font-family: "Helvetica Neue", "Arial" !important ;
  overscroll-behavior-y: none;
}

body::-webkit-scrollbar {
  display: none;
}
